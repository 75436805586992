.popupModalSmall {
  max-width: 100%;
  width: 458px;
  padding: 20px;
  position: relative;
}
.popupModalSmallCont {
  text-align: center;
  padding: 45px 10px;
  border-bottom: 0.5px solid #dfdfdf;
}
.popupModalSmallCont h3 {
  font-size: 18px;
  line-height: 22px;
  color: #444444;
  font-weight: normal;
  font-family: 'Conv_AtlasGrotesk-Medium-App';
  margin-bottom: 40px;
}
.popupModalSmallCont p {
  font-size: 16px;
  line-height: 24px;
  font-family: 'Conv_AtlasGrotesk-Regular-App';
  color: #444444;
  width: 85%;
  margin: 0 auto;
}
.popupModalSmallBtn {
  padding-top: 20px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
}
.popupModalSmallBtn button {
  width: auto;
  padding: 0 45px;
}
.popupModalSmallBtn button:first-child {
  padding: 0 25px;
  background: transparent !important;
  color: #000;
}
.staffViewHead span.staffBlocked{
  color: #cb0000;
}
