
.contactsMain {
    padding: 0 25px 10px;
    width: 78%;
    position: relative;
}
.villageIn .noResult {
    position: absolute;
}
.contactsIn {
    box-shadow: 0px 0px 8px 2px rgba(0, 0, 0,0.1);
    margin-top: 25px;
}
.contactLogo img {
    width: 70px;
    height: 70px;
    margin: 0 auto;
    border-radius: 100%;
    overflow: hidden;
    object-fit: cover;
    object-position: center center;
}
.contactLogo{
    margin-bottom: 20px;
}
.contactsIn ul li {
    width: 33.33%;
    padding: 32px 25px;
    position: relative;
    -webkit-transition: all ease-in-out 0.3s;
    -o-transition: all ease-in-out 0.3s;
    transition: all ease-in-out 0.3s;
}
/* .contactsIn ul li:hover {
    -webkit-box-shadow: 1px 3px 11px 1px rgb(0 0 0 / 14%);
            box-shadow: 1px 3px 11px 1px rgb(0 0 0 / 14%);
    -webkit-transition: all ease-in-out 0.3s;
    -o-transition: all ease-in-out 0.3s;
    transition: all ease-in-out 0.3s;
} */
.contactsIn ul li:hover .contactEmail, 
.contactsIn ul li:hover .contactPeofile small, 
.contactsIn ul li:hover .contactPeofile span, 
.contactsIn ul li:hover h4 {
    color: #095967;
    -webkit-transition: all ease-in-out 0.3s;
    -o-transition: all ease-in-out 0.3s;
    transition: all ease-in-out 0.3s;
}
.contactsIn ul li:after {
    position: absolute;
    content: "";
    background: rgba(223, 223, 223,0.6);
    height: 70%;
    width: 0.5px;
    right: 0;
    top: 50%;
    -webkit-transform: translateY(-50%);
        -ms-transform: translateY(-50%);
            transform: translateY(-50%);
}
.contactsIn ul li:before {
    position: absolute;
    content: "";
    background: rgba(223, 223, 223,0.6);
    height: 0.5px;
    width: 70%;
    left: 50%;
    bottom: 0;
    -webkit-transform: translateX(-50%);
        -ms-transform: translateX(-50%);
            transform: translateX(-50%);
}


.contactsIn ul li:nth-child(3n):after,
.contactsIn ul li:nth-child(4n):before,
.contactsIn ul li:nth-child(5n):before,
.contactsIn ul li:nth-child(6n):before {
    display: none;
}
.contactsIn ul li:nth-child(3n) {
    margin-right: 0;
}
.contactsIn ul {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
        flex-wrap: wrap;
}
.villageHeading h2,
.buildingSelectContact h2 {
    font-size: 15px;
    line-height: 20px;
    color: #000;
    font-weight: normal;
    font-family: 'Conv_AtlasGrotesk-Regular-App';
    margin-right: 5px;
    text-transform: capitalize;
}
.contactPeofile span,.contactPeofile small {
    font-size: 15px;
    line-height: 20px;
    font-family: 'Conv_AtlasGrotesk-Regular-App';
    color: #838384;
}
.contactPeofile span:first-child {
    padding-right: 5px;
    border-right: 1px solid #dfdfdf;
    margin-right: 5px;
    color: #000;
}
.contactPeofile {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
}
.contactsIn ul li h4 {
    font-size: 13px;
    line-height: 17px;
    text-align: center;
    width: 80%;
    margin: 5px auto 0;
    color: #838384;
	font-family: 'Conv_AtlasGrotesk-LightItalic-App';
    font-style: italic;
    border-top: 0.5px solid #dfdfdf;
    padding-top: 15px;
    min-height: 55px;
}
.contactEmail img {
    margin-right: 5px;
}
.contactEmail {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    margin-top: 10px;
    font-size: 13px;
    line-height: 17px;
    color: #444444;
    font-family: 'Conv_AtlasGrotesk-Regular-App';
}
.buildingSelectContact {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
            padding-top: 30.5px;
}
.contactsIn.financeIn li:last-child:after {
    display: none;
}


.documentSubHead p {
    font-size: 13px;
    line-height: 20px;
    color: #444444;
    margin-top: 20px;
    font-family: 'Conv_AtlasGrotesk-Regular-App';
}

.documentsContent h6 {
    font-size: 13px;
    line-height: 18px;
    color: #838384;
    font-weight: normal;
    font-family: 'Conv_AtlasGrotesk-Regular-App';
    width: 90%;
    margin: 5px auto 0;
    border-top: 0.5px solid #dfdfdf;
    padding-top: 10px;
    min-height: 90px;
    -webkit-transition: all ease-in-out 0.3s;
    -o-transition: all ease-in-out 0.3s;
    transition: all ease-in-out 0.3s;
}
.documentsContent h5 {
    font-size: 15px;
    line-height: 19px;
    color: #000;
    margin-bottom: 0;
    font-weight: normal;
    font-family: 'Conv_AtlasGrotesk-Regular-App';
    -webkit-transition: all ease-in-out 0.3s;
    -o-transition: all ease-in-out 0.3s;
    transition: all ease-in-out 0.3s;
}
.documentsContent {
    text-align: center;
}

.contactsIn.financeIn ul {
    padding: 0;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    height: 100%;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
}
.contactsIn.financeIn{
    height: calc(100vh - 250px);
}
.contactsIn.documentsIn {
    min-height: calc(100vh - 266px);
}
.contactsIn{
    min-height: calc(100vh - 250px);
}
.contactsIn.financeIn ul li:before{
    display: none;
}
.financeIn .contactLogo img {
    width: 115px;
    height: 115px;
    margin: 0 auto;
}


.documentLogo {
    width: 50px;
    height: 50px;
    margin: 0 auto 13px;
    text-align: center;
    border-radius: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}
.documentsIn li a:hover h5{
    color: #095967;
    -webkit-transition: all ease-in-out 0.3s;
    -o-transition: all ease-in-out 0.3s;
    transition: all ease-in-out 0.3s;
}
.documentsIn li a:hover h6{
    color: #095967;
    -webkit-transition: all ease-in-out 0.3s;
    -o-transition: all ease-in-out 0.3s;
    transition: all ease-in-out 0.3s;
}
.documentsIn ul li .documentLogo img:last-child {
    display: none;
}

.documentsIn ul li:hover .documentLogo img:first-child {
    display: none;
}
.documentsIn ul li:hover .documentLogo img:last-child {
    display: block;
}

.buildingSelectContact .MuiSelect-select.MuiSelect-select {
    padding-bottom: 0;
    padding-top: 0;
    margin-bottom: -1px;
}