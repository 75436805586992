.MuiInputLabel-formControl {
  transform: translate(0, 5px) scale(1) !important;
}
.MuiInputLabel-shrink {
  transform: translate(0, -17px) scale(1) !important;
  color: #095967 !important;
}
.MuiMenuItem-root.MuiListItem-button {
  min-height: auto;
  border-bottom: 1px solid #dfdfdf;
  color: #838384;
  font-size: 13px;
  line-height: normal;
  font-family: 'Conv_AtlasGrotesk-Regular-App';
  padding: 15px 0 15px 15px;
}
.MuiPaper-root.MuiMenu-paper.MuiPopover-paper {
  border-radius: 0;
  -webkit-box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.12);
    box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.12);
    margin-top: 3px;
}
.MuiListItem-root.Mui-selected,
.MuiListItem-root.Mui-selected:hover {
  background-color: transparent !important;
  color: #095967;
}
.MuiMenu-list {
  padding: 0 !important;
}
.MuiBreadcrumbs-separator {
  font-size: 0;
  border-left: 1px solid #f1f3f4;
  margin: 0 20px !important;
  height: 30px;
}
span.closeIcon {
  right: 20px !important;
}
.popupClose {
  cursor: pointer;
}
