.pageContainer.tableContainer,
.pageContainer.filterContainer {
  margin-top: 0;
}
.breadcrumbs ol li,
.breadcrumbs ol li:hover,
.breadcrumbs ol li:focus {
  font-size: 14px;
  color: #838384;
  font-family: 'Varta', sans-serif;
}
.breadcrumbs ol li a,
.breadcrumbs ol li a :hover,
.breadcrumbs ol li a :focus {
  text-decoration: none;
  color: #838384;
  font-family: 'Varta', sans-serif;
}
.filterDropdownin {
  padding: 20px 15px;
}
.filterDropdownin input {
  border-radius: 3px;
  background-color: rgba(216, 216, 216, 0.19);
  background-image: url(../../images/search.svg);
  background-repeat: no-repeat;
  background-position: center left 15px;
  background-size: 15px;
  height: 44px;
  line-height: 44px;
  padding: 0 15px;
  padding-left: 40px;
  width: 100%;
  display: block;
  font-size: 14px;
  font-family: 'Varta', sans-serif;
  color: #444444;
}
.filterDropdownin .MuiInputBase-root:before,
.filterDropdownin .MuiInputBase-root:after {
  display: none;
}
.filterDropdownin .MuiInputBase-root {
  width: 100%;
}
.filterDropdown {
  width: 300px;
}
.filterCheckbox h4 {
  font-size: 12px;
  opacity: 0.5;
}

.pageContainer.tableContainer,
.pageContainer.filterContainer {
  margin-top: 0;
}
.breadcrumbs ol li,
.breadcrumbs ol li:hover,
.breadcrumbs ol li:focus {
  font-size: 14px;
  color: #838384;
  font-family: 'Varta', sans-serif;
}
.breadcrumbs ol li a,
.breadcrumbs ol li a :hover,
.breadcrumbs ol li a :focus {
  text-decoration: none;
  color: #838384;
  font-family: 'Varta', sans-serif;
}

.tableContainer .MuiTableBody-root tr.MuiTableRow-root:hover > td,
.tableContainer .MuiTableBody-root tr.MuiTableRow-root:hover th {
  background: rgba(0, 0, 0, 0.02);
  color: #000;
  cursor: pointer;
  /* transition: all ease-in-out 0.5s; */
}

/* .tableContainer tr.MuiTableRow-root, 
.tableContainer tr.MuiTableRow-root td, 
.tableContainer tr.MuiTableRow-root th{
  transition: all ease-in-out 0.5s;
} */
.tableContainer .active:after {
  transform: translateY(-50%) rotate(180deg) !important;
  transition: all ease-in-out 0.5s;
}
.tableContainer th:after {
  transition: all ease-in-out 0.5s;
}
.rowEllips {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
.noResult h2,
.noResult p {
  font-size: 20px;
  font-weight: bold;
  color: rgba(131, 131, 132, 0.43);
  margin-bottom: 10px;
  font-family: 'Varta', sans-serif;
}
.noResult h2 {
  text-transform: uppercase;
}
.noResult {
  text-align: center;
  position: fixed;
  left: 0;
  right: 0;
  margin: 0 auto;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}
/* .tenantUserListTable th.noSort:nth-last-child(3):after {
  display: none !important;
}
.tenantUserListTable th.noSort:nth-last-child(3) {
  pointer-events: none;
} */
/* .editIconDiv{
  position: relative;
  z-index: 2;
} */

.pageContainer.filterContainer {
  padding: 8px 30px;
}


.viewStaffPopup {
  max-width: 100%;
  width: 720px;
  padding: 20px;
  position: relative;
}
.popUpHeader,
.staffViewHead {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}
.popUpBody {
  padding: 35px 25px;
}
.staffViewDetails {
  margin-top: 45px;
}
.staffViewDetails h2 {
  font-size: 42px;
  line-height: 46px;
  color: #000;
  font-family: 'Conv_AtlasGrotesk-Regular-App';
  font-weight: normal;
  text-transform: uppercase;
  word-break: break-word;
}
.staffViewDetails ul {
  margin-top: 45px;
}
.staffViewDetails ul li {
  font-size: 18px;
  line-height: 22px;
  margin-bottom: 15px;
  font-family: 'Conv_AtlasGrotesk-Regular-App';
  color: #444444;
}
.staffViewDetails ul li:last-child {
  margin-bottom: 0;
}
.MuiDialog-paperWidthSm {
  max-width: 100% !important;
  margin: 0 !important;
  box-shadow: 1px 3px 11px 1px rgba(0, 0, 0, 0.14) !important;
}
.MuiBackdrop-root{
  background: rgba(255, 255, 255, 0.5) !important;
}
.staffViewHead span,
.staffViewHead small {
  font-size: 18px;
  line-height: 22px;
  color: #095967;
  font-family: 'Conv_AtlasGrotesk-Regular-App';
}
.staffViewHead small {
  color: #444444;
}
.popupClose,
.popupEdit{
  cursor: pointer;
}

.tableContainer .MuiTableBody-root tr.MuiTableRow-root td img:last-child, 
.tableContainer .MuiTableBody-root tr.MuiTableRow-root th img:last-child{
  display: none;
}
.tableContainer .MuiTableBody-root tr.MuiTableRow-root:hover img:last-child, 
.tableContainer .MuiTableBody-root tr.MuiTableRow-root:hover td img:last-child, 
.tableContainer .MuiTableBody-root tr.MuiTableRow-root:hover th img:last-child{
  display: block;
}
.tableContainer .MuiTableBody-root tr.MuiTableRow-root:hover img:first-child, 
.tableContainer .MuiTableBody-root tr.MuiTableRow-root:hover td img:first-child, 
.tableContainer .MuiTableBody-root tr.MuiTableRow-root:hover th img:first-child{
  display: none;
}
.tenantUserListTable thead tr{
  box-shadow: 2px 0px 5px rgb(0, 0, 0, 0.12);
}

.addForm ul li .MuiInputBase-root.Mui-disabled {
  padding-right: 10px;
}
.addForm ul li .MuiInputBase-root.Mui-disabled input {
  padding-right: 0;
  overflow: hidden;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  pointer-events: none;
}

.infoTitleData p{
  padding-top: 15px;
}

.tooltipInfo {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
span.tooltipInfoIcon {
  margin-left: 5px;
  margin-top: 0;
}

