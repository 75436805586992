.footerIn {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}
.footerIn ul {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.footerNav ul li {
  margin-right: 30px;
}
.footerNav ul li:last-child {
  margin-right: 0;
}
.footerNav li a {
  font-size: 13px;
  line-height: 17px;
  color: #2a2a2a;
}
.footerNav li a:hover{
  color: #f14800;
}
.footerSocialIcon li a {
  color: #2a2a2a;
}
.footerSocialIcon li a:hover{
  color: #f14800;

}

.footerSocialIcon ul li a svg {
  font-size: 20px;
}
.footerSocialIcon ul li:last-child {
  margin-right: 0;
}
.footerSocialIcon ul li {
  margin-right: 15px;
}

.tenantHomeFooter {
  /* padding: 0 25px; */
}
.tenantHomeFooter {
  padding: 12px 25px;
  border-top: 1px solid rgba(0, 0, 0, 0.08);
}
.tenantHomeHeader{
  border-bottom: 1px solid rgba(0, 0, 0, 0.08);

}

.footerNav li.copyright a {
  color: #767676;
  pointer-events: none;
}