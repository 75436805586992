.buildingGallery video {
  /* object-fit: fill; */
  width: 100%;
}
/* .slick-slide img {
  width: 100%;
} */

.slick-dots li {
  width: 17%;
  height: auto;
}
.slick-dots {
  position: static;
  padding: 0;
  margin-top: 20px;
}
.slick-dots li {
  width: 18%;
  height: auto;
  margin: 0 0 0 20px;
}

.slick-dots li:first-child {
  margin-left: 0;
}
.slick-prev {
  left: 10px;
  z-index: 1;
}
.slick-next {
  right: 10px;
}

.slick-prev,
.slick-next {
  width: 40px;
  height: 40px;
  border-radius: 100%;
  background: transparent;
  background: #0000004f;
  transition: all ease-in-out 0.3s;
  -webkit-transition: all ease-in-out 0.3s;
}
.slick-prev:hover,
.slick-prev:focus,
.slick-next:hover,
.slick-next:focus {
  background: #0000004f;
  transition: all ease-in-out 0.3s;
  -webkit-transition: all ease-in-out 0.3s;
}
.slick-prev:before {
  content: '';
  background: url(../../images/right-arw.svg) no-repeat;
  background-position: center center;
  background-size: contain;
  height: 16px;
    width: 16px;
    position: absolute;
    top: 50%;
    right: 14px;
    margin: 0 auto;
  transform: translateY(-50%) rotate(180deg);
}
.slick-next:before {
  content: '';
  background: url(../../images/right-arw.svg) no-repeat;
  background-position: center center;
  background-size: contain;
  height: 16px;
    width: 16px;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 14px;
    margin: 0 auto;
}
.slick-prev:before,
.slick-next:before {
  opacity: 1;
}

/* Custom Slider */

.prev,
.next {
  cursor: pointer;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  font-size: 18px;
  transition: 0.6s ease;
  border-radius: 0 3px 3px 0;
  user-select: none;
}
.next {
  right: 0;
  border-radius: 3px 0 0 3px;
}

.fade {
  -webkit-animation-name: fade;
  -webkit-animation-duration: 1.5s;
  animation-name: fade;
  animation-duration: 1.5s;
}

@-webkit-keyframes fade {
  from {
    opacity: 0.4;
  }
  to {
    opacity: 1;
  }
}

@keyframes fade {
  from {
    opacity: 0.4;
  }
  to {
    opacity: 1;
  }
}

.mySlides img {
  width: 100%;
}
.mySlides {
  height: 450px;
  position: relative;
  overflow: hidden;
}
.mySlides video {
  width: 100%;
  height: 100%;
  object-fit: fill;
}
button.next img {
  transform: rotate(180deg);
}
.slideButtons button img {
  margin: 0 auto;
}
.slideButtons button {
  width: 45px;
  height: 110px;
  background: rgba(0, 0, 0, 0.3) !important;
  border: none;
  padding: 0;
  outline: none;
  text-align: center;
  border-radius: 0;
}
.slideshow-container {
  position: relative;
}
.sliderThumnlain > div:first-child {
  margin-left: 0;
}
.sliderThumnlain > div {
  width: 18%;
  margin-left: 20px;
}
.sliderThumnlain {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
.sliderThumnlainMain {
  position: relative;
  margin-top: 15px;
  height: 80px;
  overflow: hidden;
}
.mySlidesThumb {
  cursor: pointer;
}
.sliderThumnlainMain .slideButtons button {
  height: 95px;
  top: 0;
  transform: none;
  background: rgba(0, 0, 0, 0.3) !important;
}
.noGallery {
  height: 97%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  border: solid 2px rgba(151, 151, 151,0.2);
  background-color: rgba(216, 216, 216, 0.3);
  font-size: 15px;
  font-weight: 500;
  color: #dfdfdf;
}

.buildingNoprevImg{
  min-height: 300px;
  height: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  border: solid 2px rgba(151, 151, 151,0.2);
  background-color: rgba(216, 216, 216, 0.3);
  font-size: 15px;
  font-weight: 500;
  color: #dfdfdf;
}
.buildingSlideImage{
  background-position: center center;
  background-size: contain;
  background-repeat: no-repeat;
  height: 430px;
}


.slick-slider.slickMainSlider {
  min-height: 430px;
}