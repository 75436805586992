html,
body,
div,
span,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
abbr,
address,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
samp,
small,
strong,
sub,
sup,
var,
b,
i,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  font-size: 100%;
  vertical-align: baseline;
  background: transparent;
}
body {
  line-height: 1;
}
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}
nav ul {
  list-style: none;
}
blockquote,
q {
  quotes: none;
}
blockquote:before,
blockquote:after,
q:before,
q:after {
  content: none;
}
h1,h2,h3,h4,h5,h6{
  font-weight: normal;
}
html {
  scroll-behavior: smooth;
}
a {
  margin: 0;
  padding: 0;
  font-size: 100%;
  vertical-align: baseline;
  background: transparent;
}
a,button {
  outline: none;
  transition: all 0.2s ease-in-out;
  -webkit-transition: all 0.2s ease-in-out;
}

a:hover, a:focus,
button:hover, button:focus  {
  text-decoration: none;
  transition: all 0.2s ease-in-out;
  -webkit-transition: all 0.2s ease-in-out;
}

ins {
  background-color: #ff9;
  color: #000;
  text-decoration: none;
}
mark {
  background-color: #ff9;
  color: #000;
  font-style: italic;
  font-weight: bold;
}
del {
  text-decoration: line-through;
}
abbr[title],
dfn[title] {
  border-bottom: 1px dotted;
  cursor: help;
}
table {
  border-collapse: collapse;
  border-spacing: 0;
}
hr {
  display: block;
  height: 1px;
  border: 0;
  border-top: 1px solid #ccc;
  margin: 1em 0;
  padding: 0;
}
input,
select {
  vertical-align: middle;
}
*,
*:after,
*:before {
  box-sizing: border-box;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  -ms-box-sizing: border-box;
  -o-box-sizing: border-box;
}
li {
  list-style: none;
}
img {
  display: block;
  max-width: 100%;
  height: auto;
}
a{
  text-decoration: none !important;
  cursor: pointer;
}
input::-ms-reveal,
input::-ms-clear {
  display: none;
}

@font-face {
	font-family: 'Conv_AtlasGrotesk-Light-App';
	src: url('../../fonts/AtlasGrotesk-Light-App.eot');
	src: local('☺'), url('../../fonts/AtlasGrotesk-Light-App.woff') format('woff'), 
  url('../../fonts/AtlasGrotesk-Light-App.ttf') format('truetype'), 
  url('../../fonts/AtlasGrotesk-Light-App.svg') format('svg');
	font-weight: normal;
	font-style: normal;
}
@font-face {
	font-family: 'Conv_AtlasGrotesk-Bold-App';
	src: url('../../fonts/AtlasGrotesk-Bold-App.eot');
	src: local('☺'), url('../../fonts/AtlasGrotesk-Bold-App.woff') format('woff'), 
  url('../../fonts/AtlasGrotesk-Bold-App.ttf') format('truetype'), 
  url('../../fonts/AtlasGrotesk-Bold-App.svg') format('svg');
	font-weight: normal;
	font-style: normal;
}
@font-face {
	font-family: 'Conv_AtlasGrotesk-LightItalic-App';
	src: url('../../fonts/AtlasGrotesk-LightItalic-App.eot');
	src: local('☺'), url('../../fonts/AtlasGrotesk-LightItalic-App.woff') format('woff'), 
  url('../../fonts/AtlasGrotesk-LightItalic-App.ttf') format('truetype'), 
  url('../../fonts/AtlasGrotesk-LightItalic-App.svg') format('svg');
	font-weight: normal;
	font-style: normal;
}
@font-face {
	font-family: 'Conv_AtlasGrotesk-Medium-App';
	src: url('../../fonts/AtlasGrotesk-Medium-App.eot');
	src: local('☺'), url('../../fonts/AtlasGrotesk-Medium-App.woff') format('woff'), 
  url('../../fonts/AtlasGrotesk-Medium-App.ttf') format('truetype'),
  url('../../fonts/AtlasGrotesk-Medium-App.svg') format('svg');
	font-weight: normal;
	font-style: normal;
}
@font-face {
	font-family: 'Conv_AtlasGrotesk-Regular-App';
	src: url('../../fonts/AtlasGrotesk-Regular-App.eot');
	src: local('☺'), url('../../fonts/AtlasGrotesk-Regular-App.woff') format('woff'), 
  url('../../fonts/AtlasGrotesk-Regular-App.ttf') format('truetype'), 
  url('../../fonts/AtlasGrotesk-Regular-App.svg') format('svg');
	font-weight: normal;
	font-style: normal;
}



body {
  margin: 0;
  padding: 0;
  font-family: 'Conv_AtlasGrotesk-Regular-App';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.pageContainer {
  padding: 12px 25px;
  max-width: 1600px;
  margin: 0 auto;
}

* {
  scrollbar-width: thin;
  /* scrollbar-color: #f8f8f8 #ededed; */
}
*::-webkit-scrollbar {
  width: 12px;
  opacity: 0;
}
*::-webkit-scrollbar-track {
  background: #fff;
  opacity: 0;
}
*::-webkit-scrollbar-thumb {
  background-color: #dddddd;
  border-radius: 20px;
  border: 3px solid #fff;
  opacity: 0;
}
.tenantHomePage,
.pagecontainer {
  max-width: 1600px;
  margin: 0 auto;
  position: relative;
}
.tenantPortalApp {
  background: url(../../images/Main-Page.jpg) no-repeat;
  background-size: cover;
  background-position: center center;
  height: 100vh;
}
.tenantPortalApp.tenantPortalforgotApp {
  background: url(../../images/forgotPassword.jfif) no-repeat;
  background-size: cover;
  background-position: center center;
}
.tpFormBox {
  padding: 70px 60px;
  box-shadow: 1px 3px 11px 1px rgb(0 0 0 / 14%);
  background-color: #fff;
  max-width: 550px;
  margin: 0 auto;
  position: relative;
  top: 50%;
  transform: translateY(-50%);
}
.tpFormBox h1 {
  font-size: 30px;
  line-height: 36px;
  color: #000;
  font-family: 'Conv_AtlasGrotesk-Medium-App';
}
.tpFormBox .form {
  margin-top: 30px;
}
.form ul li.forgotPassword button {
  font-size: 16px;
  line-height: 20px;
  color: #444444;
  font-family: 'Conv_AtlasGrotesk-Medium-App';
  text-decoration: none;
  box-shadow: none;
  background: transparent;
  text-transform: inherit;
  padding: 0;
}
.form ul li label {
  font-size: 16px;
  line-height: 18px;
  color: #444444;
  display: block;
  margin-bottom: 10px;
  font-family: 'Conv_AtlasGrotesk-Regular-App';
}
.form ul li .MuiInputLabel-shrink {
  transform: translate(0, -17px) scale(1) !important;
  color: #b8b8b9 !important;
  font-size: 14px !important;
  font-family: 'Conv_AtlasGrotesk-Regular-App';
}
.form ul li {
  margin-top: 50px;
}
.form ul li:nth-last-child(2) {
  margin-top: 50px;
}
.form ul li div:after,
.form ul li div:before {
  display: none;
}
.form ul li div {
  width: 100%;
  margin-top: 0;
}
.form ul li input {
  border-bottom: solid 1px #838384;
  /* padding: 0 0 5px; */
  font-size: 16px;
  line-height: normal;
  color: #444444;
}
.errorLocked {
  font-size: 16px;
  line-height: 20px;
  font-family: 'Conv_AtlasGrotesk-Medium-App';
  color: #cb0000;
  margin-top: 15px;
}
.fieldvalidation {
  font-size: 14px;
  line-height: 20px;
  color: #444444;
  margin: 25px 0;
}
.fieldvalidationMsg {
  font-size: 16px;
  line-height: 22px;
  color: #444444;
  margin: 25px 0;
}
.resetTenantPortalApp {
  background: url(../../images/reset-banner.png) no-repeat;
  background-size: cover;
  background-position: center center;
}
.form .MuiCircularProgress-svg {
  color: #fff;
}

/*==========HOME-PAGE===========*/

.tenantHomeHeaderWrap {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.tenantHomeHeaderLogo {
  width: 135px;
}
.tenantHomeHeaderNav {
  width: 40%;
}
.tenantHomeHeaderNav ul {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.tenantHomeHeaderNav ul li {
  text-align: right;
  width: auto;
  display: block;
}
.tenantHomeHeaderNav ul li button {
  background: transparent !important;
  font-size: 15px;
  line-height: 19px;
  color: #000;
  text-transform: inherit;
  font-family: 'Conv_AtlasGrotesk-Regular-App';
  padding: 0;
}
.tenantHomeHeaderNav ul li {
  font-size: 15px;
  line-height: 19px;
  font-family: 'Conv_AtlasGrotesk-Regular-App';
  text-transform: capitalize;
  color: #000;
}
.tenantHomeHeaderNav ul li:first-child {
  border-right: 1px solid #f1f3f4;
}
.tenantHomeHeaderWrap {
  padding: 10px 0;
}
.tenantHomeHeader {
  position: sticky;
  top: 0;
  background: #fff;
  z-index: 2;
  width: 100%;
  /* -webkit-backdrop-filter: blur(10px);
          backdrop-filter: blur(10px); */
}
.tenantHomeHeaderMain{
  padding: 0 25px;

}

.tenantPortalNav ul li a {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  text-decoration: none;
  font-size: 15px;
  line-height: 19px;
  font-family: 'Conv_AtlasGrotesk-Regular-App';
  color: #000;
  padding-bottom: 15px;
}
.tenantPortalNav li span {
  margin-right: 10px;
  width: 35px;
}
/* .tenantPortalNav li span img{
  min-width: 25px;
} */
.tenantPortalNav li a small {
  display: block;
  color: #a3a3a3;
  font-size: 13px;
  line-height: 17px;
  font-family: 'Conv_AtlasGrotesk-Regular-App';
}
.tenantPortalNav {
  width: 22%;
  border-right: 1px solid #f1f3f4;
  height: calc(100vh - 122px);
  position: sticky;
  top: 0;
}
.tenantNews {
  width: 56%;
  padding: 0 23px;
  margin: 12px 0 0;
  /* border-left: 1px solid #f1f3f4; */
  border-right: 1px solid #f1f3f4;
}
.tenantPersonalInfo {
  width: 22%;
  padding: 0 0 0 23px;
  margin: 0 0 0;
  top: 0;
  transition: all ease-in-out 0.5s;
  position: sticky;
}
.tenantPersonalInfoSticky{

  height: calc(100vh - 120px);
  overflow-y: auto;
  padding-top: 12px;
}

.cover-bar {
  position: absolute;
  background: #fff;
  height: 100%;  
  top: 0;
  right: 0;
  width: 10px;
  transition: all .5s;
  -webkit-transition: all .5s;
  opacity: 1;
}
.tenantPersonalInfoSticky:hover .cover-bar {
   opacity: 0;
   transition: all .5s;
  -webkit-transition: all .5s;
  z-index: -1;
}





.tenantPortalNav ul {
  padding: 0;
}
.tenantPortalNav ul li {
  padding: 15px 0 0 25px;
  display: block;
}
.tenantPortalNav ul li.Mui-selected,
.tenantPortalNav ul li.Mui-selected:hover {
  background: #d8d8d8;
}
.tenantPortalNav ul li:hover {
  background: rgba(0, 0, 0, 0.08);
}
.tenantPortalNav ul li.Mui-selected a,
.tenantPortalNav li.Mui-selected a small {
  color: #095967;
}
.tenantPortalNav ul li span img:last-child{
  display: none;
}
.tenantPortalNav ul li.Mui-selected span img:last-child{
  display: block;
}
.tenantPortalNav ul li.Mui-selected span img:first-child{
  display: none;
}

.tenantHomeWrap {
  padding: 0 0px 0 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  max-height: calc(100vh - 122px);
  /* padding-top: 73px; */
  overflow-y: auto;
  /* -webkit-box-align: start;
-ms-flex-align: start;
        align-items: flex-start; */
}

.leftNav .tenantPortalNav {
  width: 85%;
  margin: 0 auto;
}
/* .leftNav{
  position: sticky !important;
  top: 76px;
} */
/* .tenantPersonalInfoSticky {
  position: sticky;
  top: 0;
} */
.tenantNews h2,
.tenantPersonalInfo h2 {
  font-size: 12px;
  line-height: 16px;
  font-weight: normal;
  color: #838384;
  margin-bottom: 11px;
  font-family: 'Conv_AtlasGrotesk-Regular-App';
  text-transform: capitalize;
}

.newsAuthor {
  border-right: 1px solid #dfdfdf;
  padding-right: 8px;
  margin-right: 8px;
}
.newsAuthorDtls,
.newsAuthorDtls > div {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.newsAuthorDtls > div {
  font-size: 13px;
  line-height: 17px;
  color: #838384;
  font-family: 'Conv_AtlasGrotesk-Regular-App';
}
.newsAuthor img {
  position: relative;
  top: 1px;
}
/* .newsAuthor img {
  width: 16px;
} */
/* .newsBlock .newsAuthor img {
  width: 30px;
} */
.newsAuthor span {
  color: #095967;
  margin-left: 3px;
  text-transform: capitalize;
}
.noticeBlock .newsAuthor span {
  max-width: 170px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
.newsTitle {
  font-size: 15px;
  line-height: 20px;
  margin: 12px 0;
  font-family: 'Conv_AtlasGrotesk-Regular-App';
  color: #000;
  display: inline-block;
  text-decoration: none;
}
.noticeBoardInfo .newsTitle {
  margin: 8px 0;
}
.newsDescFull img {
  margin-bottom: 10px;
}
.newsDescFull p,
.newsDescFull ul li,
.newsDescFull ol li {
  font-size: 13px;
  line-height: 22px;
  margin-bottom: 25px;
  word-break: break-word;
  color: #444444;
  font-family: 'Conv_AtlasGrotesk-Regular-App';
}
.newsDescEditor p br {
  display: none;
}
.newsDescEditor p img{
  margin-top: 10px;
}
.newsDescFull.newsDescEditor.ql-editor ol, .newsDescFull.newsDescEditor.ql-editor ul {
  padding: 0;
}
.newsDescFull.newsDescEditor.ql-editor {
  padding: 0;
}
.noticeBoardInfo .newsDescFull p {
  font-size: 13px;
  line-height: 22px;
  margin-bottom: 15px;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.tenantNews hr,
.tenantPersonalInfo hr {
  margin: 20px 0;
  background: #dfdfdf;
}
.tenantPersonalInfo hr {
  margin: 0 0 15px;
}
.tenantPersonalInfo .rwadMoreLink {
  text-decoration: none;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  font-size: 12px;
  color: #f14800;
  line-height: 16px;
  font-family: 'Conv_AtlasGrotesk-Regular-App';
  margin: 15px 0;
  text-transform: capitalize;
}

.noticeBoardInfo span.imgnewsTitle  {
  margin: 0 0 15px 0;
  display: block;
}
.newsTitle.newsTitleInner {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
.NewsCategory {
  border: 1px solid #dddddd;
  border-radius: 30px;
  padding: 6px 20px;
  color: #a7a7a7;
  font-family: 'Conv_AtlasGrotesk-Light-App';
  font-size: 13px;
  line-height: normal;
}

/* .noticeBoardInfo {
  max-height: 400px;
  overflow-y: scroll;
} */
/* .buildingInfo {
  background: #fff;
  overflow-y: scroll;
  max-height: 38vh;
} */

/* img {
  margin: 0 !important;
  height: auto !important;
    width: auto !important;
    max-width: 100%;
} */
.BuildingImage img {
  margin-bottom: 15px !important;
}
.buildingName {
  font-size: 15px;
  line-height: 20px;
  margin: 12px 0 5px;
  font-family: 'Conv_AtlasGrotesk-Regular-App';
  color: #000;
}
.buildingLocation {
  font-size: 13px;
  line-height: 20px;
  margin-bottom: 25px;
  color: #444444;
  font-family: 'Conv_AtlasGrotesk-Regular-App';
}
.newsDescFullImg {
  position: relative;
  margin-bottom: 20px;
}
.newsDescFullImg img{
  width: auto !important;
  height: auto !important;
  margin: 0 !important;
}
.newsDescFullImg button {
  position: absolute;
  left: 0;
  bottom: 0;
  background: transparent !important;
  height: auto;
  width: auto;
  padding: 0;
  margin: 0;
  border: none;
  outline: none;
  cursor: pointer;
}
.newsDescFull video {
  width: 100%;
}
.newsDescFullImg a {
  display: block;
}
.borderLine {
  border-bottom: 1px solid #f1f3f4;
  height: 0;
  width: 80%;
  margin: 0 auto;
}
.tenantNews .borderLine {
  width: 95%;
  margin: 0 auto 20px;
}
.noticeBlockWrap {
  padding-top: 0;
}
.noticeBlock {
  margin-top: 15px;
}
.buildingInfo {
  padding-top: 20px;
  border-top: 1px solid #dfdfdf;
}
.tenantPortalNav .borderLine {
  width: 95%;
  margin: 0;
}
ul li .MuiButton-root.Mui-disabled {
  background: #dfdfdf;
  color: #fff;
}
.MuiTable-stickyHeader {
  border-collapse: collapse;
}

.MuiCircularProgress-root {
  left: 0;
  right: 0;
  margin: 0 auto;
  position: absolute;
  top: 50%;
  transform: translateY(-50%) !important;
}
.MuiCircularProgress-root.MuiCircularProgress-colorPrimary {
  color: #000000;
}
/* .MuiTableCell-stickyHeader {
  top: 1px !important;
} */

/* 
.pageContainer.tableContainer.tenantUserListTable {
  height: calc(100vh - 250px);
} */

.popupBadge {
  top: 0;
  left: 50%;
  color: #444444;
  right: 0;
  width: auto;
  margin: 0 auto;
  display: table;
  padding: 20px 40px;
  z-index: 999;
  position: fixed;
  font-size: 18px;
  transform: translateX(-50%);
  background: #fff;
  box-shadow: 1px 3px 11px 1px rgb(0 0 0 / 14%);
  text-align: center;
  border-radius: 0;
}

.tpFormBox.readyToGo {
  text-align: center;
  padding: 120px 70px;
}
.tpFormBox.readyToGo h3 {
  padding-bottom: 25px;
  font-size: 20px;
  line-height: 24px;
  font-weight: bold;
}
.tpFormBox.readyToGo .fieldvalidation {
  margin-bottom: 60px;
  font-size: 16px;
}
.tpFormBox.readyToGo button {
  width: auto;
  padding: 0 45px;
}

.crcularprogressSignin {
  animation: none !important;
  top: 50%;
  transform: none !important;
}
.signingInWait {
  font-size: 16px;
  line-height: 18px;
  color: #444444;
  display: block;
  font-family: 'Conv_AtlasGrotesk-Regular-App';
  padding-top: 0;
  text-align: center;
  top: -40px;
  position: relative;
}


.tenantHomePage .MuiLinearProgress-colorSecondary {
  background-color: #a3a3a3;
}
.tenantHomePage .MuiLinearProgress-barColorSecondary {
  background-color: #000;
}
.tenantHomePage .MuiLinearProgress-root{
  height: 2px;
}

.TflUpdate {
  margin-top: 25px;
}
.TflUpdate h2{
  padding-top: 15px;

}

.newsDescFull video {
  width: 100%;
  height: 430px;
  background: #000;
}

.ppCheckboxMain {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: start;
      -ms-flex-align: start;
          align-items: flex-start;
}
.form ul li div.ppCheckbox {
  width: 40px;
}
.ppCheckbox span {
  padding: 0;
}
.form ul li .ppCheckboxMain label {
  font-size: 12px;
  line-height: 19px;
  color: #444444;
  display: block;
  font-family: 'Conv_AtlasGrotesk-Regular-App';
}
.form ul li .ppCheckboxMain label a {
  color: #444444;
  text-decoration: underline !important;
}
.ppCheckbox span svg {
  color: #979797;
}
.ppCheckbox span.Mui-checked svg {
  color: #000;
}
.form ul li.ppCheckboxList {
  margin: 30px 0 -30px;
}
.form ul li.forgotPassword {
  margin-top: 30px;
}
.noticeBoardInfo .noticeBlockWrap:last-child .borderLine {
  display: none;
}
.noticeBoardLogo {
  height: 160px;
  overflow: hidden;
  margin-bottom: 8px;
}
