.addForm ul {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  flex-wrap: wrap;
}
.addForm ul li {
  width: 100%;
}
.addForm ul li:nth-child(1),
.addForm ul li:nth-child(2),
.addForm ul li:nth-child(5),
.addForm ul li:nth-child(6) {
  width: 46%;
  position: relative;
}
.addForm ul li .popupClose {
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
}
.addForm ul li:nth-child(7) div input {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  padding-right: 20px;
}
.CreateStafBanner {
  background: url(../../images/add-user-banner.png) no-repeat;
  background-size: cover;
  background-position: center center;
  min-height: calc(100vh - 122px);
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  max-height: calc(100vh - 120px);
  overflow-y: auto;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

.staffBreadcrumbMain ol li a {
  color: #fff;
  font-size: 13px;
  font-family: 'Conv_AtlasGrotesk-Regular-App';
  line-height: 17px;
  text-decoration: none;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  align-items: center;
}
.staffBreadcrumbMain ol li a img {
  margin-right: 15px !important;
}

.staffBreadcrumbMain .MuiBreadcrumbs-separator {
  font-size: 0;
  border-left: 1px solid #f1f3f4;
  margin: 0 15px !important;
  height: 30px;
}
.staffBreadcrumbMain ol {
  align-items: center;
}
.staffBreadcrumbMain {
  padding: 20px 25px;
  position: sticky;
  top: 0;
  width: 100%;
  z-index: 3;
  /* background: rgb(208 208 208); */
}
.ptaBox {
  border-bottom: 1px solid #838384;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 15px;
}
.ptaLabel {
  color: #444444;
  width: 40%;
  font-size: 13px;
  background: transparent !important;
  font-family: 'Conv_AtlasGrotesk-Regular-App';
  line-height: 20px;
}

.button-cover,
.knobs,
.layer {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.button-cover {
  position: relative;
}
.button {
  position: relative;
  top: 50%;
  width: 60px;
  height: 30px;
  margin: 0 auto;
  overflow: hidden;
}

.button.r,
.button.r .layer {
  border-radius: 100px;
}

.checkbox {
  position: relative;
  width: 100%;
  height: 100%;
  padding: 0;
  margin: 0;
  opacity: 0;
  cursor: pointer;
  z-index: 3;
}
.knobs {
  z-index: 2;
  border-radius: 60px;
}
.layer {
  width: 100%;
  background-color: #000;
  transition: 0.3s ease all;
  z-index: 1;
  border-radius: 30px;
}
.buttonSwitch .knobs:before,
.buttonSwitch .knobs:after,
.buttonSwitch .knobs span {
  position: absolute;
  top: 2.5px;
  width: 20px;
  height: 10px;
  font-size: 10px;
  font-weight: 400;
  text-align: center;
  line-height: 1;
  padding: 0;
  border-radius: 2px;
  transition: 0.3s ease all;
}
.buttonSwitch .knobs:before,
.buttonSwitch .knobs:after {
  color: #fff;
  z-index: 1;
  top: 50%;
  transform: translateY(-50%);
}
.buttonSwitch .knobs:before {
  content: 'YES';
  left: 7px;
}
.buttonSwitch .knobs:after {
  content: 'NO';
  right: 5px;
  color: #d0d0d0;
}
.buttonSwitch .knobs span {
  width: 25px;
  left: 32px;
  background-color: #fffefe;
  z-index: 2;
  height: 25px;
  border-radius: 100%;
}
.buttonSwitch .checkbox:checked + .knobs span {
  left: 3px;
  border: solid 0.3px #dfdcdc;
  background-color: #f4f4f4;
  box-shadow: 0 0.2px 2px 0 rgb(0 0 0 / 50%),
    inset 0 0 1px 0 rgb(181 181 181 / 50%);
}
.buttonSwitch .checkbox:checked ~ .layer {
  background-color: #f4f4f4;
  box-shadow: inset 0 1px 3px 0 rgb(0 0 0 / 13%);
  border: solid 0.3px #dfdcdc;
}
li.editButtons {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
}
li.editButtons button {
  width: auto !important;
  padding: 0 56px !important;
}
li.editButtons button:first-child {
  background: transparent !important;
  color: #000;
}

.MuiFormHelperText-root.Mui-error {
  color: #cb0000;
  font-size: 12px;
  font-family: 'Conv_AtlasGrotesk-Regular-App';
  line-height: 16px;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  background: transparent !important;
}
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  box-shadow: 0 0 0 40px #fff inset !important;
  -webkit-box-shadow: 0 0 0 40px #fff inset !important;
}

.pageContainer.tableContainer.tenantUserListTable {
  min-height: calc(100vh - 244px);
  padding-bottom: 0;
}


.infoIconLabel {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  z-index: 2;
  cursor: pointer;
}

/* .infoIconLabel .tooltipInfo {
  margin-top: -2px;
} */