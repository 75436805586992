.villageIn ul {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}
.villageIn ul li {
  width: 48%;
  margin-bottom: 50px;
}
.villageLogo {
  max-height: 300px;
  min-height: 300px;
  overflow: hidden;
}
.villageLogo img {
  width: 100%;
}
.villageHeading {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}
.villageHeading h2 {
  font-size: 15px;
  line-height: 20px;
}
.viewAllVilg a {
  font-size: 15px;
  line-height: 30px;
  height: 31px;
  color: #095967;
  border: 1px solid #095967;
  display: block;
  padding: 0px 25px;
  font-family: 'Conv_AtlasGrotesk-Regular-App';
}
.villgSubHead {
  margin-top: 20px;
}
.villgSubHead p {
  font-size: 13px;
  line-height: 20px;
  color: #444444;
  font-family: 'Conv_AtlasGrotesk-Regular-App';
}
.villageIn {
  /* margin-top: 20px; */
}
.villageName {
  margin-top: 15px;
  padding-bottom: 15px;
  border-bottom: 1px solid #dfdfdf;
}
.villageName span {
  display: block;
  font-size: 13px;
  line-height: 18px;
  color: #095967;
}
.villageIn ul li a {
  display: block;
}

.spaceBuildingDetails h5 {
  font-size: 13px;
  line-height: 17px;
  margin-top: 5px;
  font-weight: normal;
  color: #3a3a3a;
  font-family: 'Conv_AtlasGrotesk-Regular-App';
}
.spaceBuildingDetails {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  margin-top: 15px;
  border-bottom: 1px solid #dfdfdf;
  align-items: flex-start;
}
.spaceBuildingDetails .villageName {
  border-bottom: none;
  margin-top: 0;
}
.deskImg {
  font-size: 13px;
  line-height: 17px;
  margin-top: 0;
  font-weight: normal;
  color: #3a3a3a;
  font-family: 'Conv_AtlasGrotesk-Regular-App';
  display: flex;
  align-items: center;
}
.unitsAvlb {
  font-size: 13px;
  line-height: 17px;
  margin-top: 0;
  font-weight: normal;
  color: #3a3a3a;
  font-family: 'Conv_AtlasGrotesk-Regular-App';
  display: flex;
  align-items: center;
}

.deskImg span:first-child {
  margin-right: 5px;
}

.deskImg span img {
  vertical-align: middle;
}

.unitFilters .unitsAvlb {
  color: #095967;
}
.unitFilters {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin-top: 25px;
}
.unitFiltersLft {
  width: 80% !important;
}
.unitFiltersLft ul {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.unitFiltersLft ul li:last-child {
  margin-right: 0;
  width: auto;
}

/*======Building-Unit-Details==========*/

.buildingFullDtls {
  padding: 0 25px;
}
.buildingFullDtls {
  padding: 0 0 0 25px;
  margin-top: 10px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  /* -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start; */
  max-height: calc(100vh - 205px);
  min-height: calc(100vh - 205px);
  overflow-y: auto;
}
.buildingGallery {
  width: 58%;
  padding-right: 20px;
  border-right: 1px solid #f1f3f4;
  /* position: sticky; */
  top: 0;
}
.unitBreadcrumb {
  position: sticky;
  top: 76px;
  z-index: 2;
  background: #fff;
}
.buildingDtlsIn {
  width: 42%;
  padding-left: 20px;
  padding-right: 20px;
  overflow: auto;
  position: relative;
}
.buildingDtlsIn h1 {
  font-size: 42px;
  line-height: 46px;
  color: #000;
  font-weight: normal;
}
.buildingFloors {
  margin-top: 22px;
  position: relative;
}
.buildingFloors ul {
  overflow-y: auto;
  max-height: 110px;
}
.buildingFloors:hover .cover-bar {
  opacity: 0;
  transition: all 0.5s;
  -webkit-transition: all 0.5s;
  z-index: -1;
}
.buildingFloors ul {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}
.buildingFloors ul li {
  width: 32%;
  margin-right: 2%;
  margin: 6px 2% 6px 0;
  z-index: 1;
}
.buildingFloors ul li:nth-child(3n) {
  margin-right: 0;
}
.buildingFloors li button {
  background: transparent;
  color: #000;
  font-size: 15px;
  width: 100%;
  border: 1px solid #444444;
  border-radius: 0;
  height: 41px;
  line-height: normal;
  padding: 0;
  margin: 0;
  outline: none;
  cursor: pointer;
}
.floorFullDesc {
  margin: 30px 0 15px;
}
.floorWidth {
  font-size: 15px;
  line-height: 19px;
  color: #3a3a3a;
  margin-bottom: 5px;
}
.floorDesc {
  font-size: 13px;
  line-height: 24px;
  color: #3a3a3a;
  margin-top: 20px;
}
.floorDesc.floorDescWordBreak {
  word-break: break-word;
}

.floorFeature .featureText {
  font-size: 13px;
  line-height: 17px;
  margin-left: 10px;
  color: #3a3a3a;
}
.floorFeature ul {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}
.floorFeature ul li {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  width: 48%;
  margin-bottom: 25px;
}
.floorFeature ul li span {
  width: 40px;
}
.floorFeature {
  margin-top: 40px;
}
.BuildingStations h3,
.stationLocation h3 {
  font-size: 15px;
  line-height: 20px;
  color: #000;
  font-weight: normal;
  font-family: 'Conv_AtlasGrotesk-Medium-App';
}
.BuildingStations ul li {
  width: 47%;
  -webkit-box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.15);
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.15);
  margin-bottom: 30px;
  border-radius: 8px;
}
.BuildingStations ul {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-top: 20px;
}
.BuildingStations {
  margin-top: 20px;
}
.streetDetails {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding: 15px;
  border-bottom: 1px solid #f1f3f4;
  min-height: 100px;
}
.streetName {
  width: 75%;
}
.streetName h4 {
  font-size: 14px;
  line-height: 18px;
  margin-bottom: 15px;
  font-weight: 600;
  min-height: 35px;
}
.streetName h5 {
  font-size: 14px;
  line-height: 18px;
  font-weight: normal;
}
.streetLogo {
  width: 15%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.streetLane p {
  font-size: 11px;
  line-height: 15px;
  color: #fff;
  background: #000;
  padding: 5px 15px;
  border-radius: 12px;
  margin: 0 10px 15px 0;
  font-family: 'Conv_AtlasGrotesk-Light-App';
}
.streetLane {
  padding: 15px 15px 0 15px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}
.buildingFloors li button.active {
  background: #000;
  color: #fff;
  font-family: 'Conv_AtlasGrotesk-Medium-App';
}

/* .filterDropdown.filterDropdownCheck {
    width: 100%;
    max-height: 290px;
}
.filtercheckTop ul {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: flex-start;
    justify-content: flex-start;
    -webkit-flex-wrap: wrap;
    flex-wrap: wrap;
}
.filtercheckTop ul li {
    width: 15.8%;
    border-bottom: 1px solid hsla(0,0%,59.2%,.15);
    margin-right: .9%;
    min-width: 190px;
    padding: 0;
}
.filtercheckTop>ul>li>label {
    width: 100%;
}
.jss229 {
    padding: 9px;
}
.filtercheckTop>ul>li label span:last-child {
    top: 2px;
    color: #444444;
    width: calc(100% - 40px);
    overflow: hidden;
    position: relative;
    font-size: 12px;
    box-sizing: border-box;
    font-family: Varta,sans-serif;
    line-height: 19px;
    white-space: nowrap;
    text-overflow: ellipsis;
}
.filtercheckTop>ul>li label span:first-child span svg {
    fill: #979797;
    font-size: 19px;
}
.filterDropdown .MuiCheckbox-colorSecondary.Mui-checked, 
.filterDropdown .MuiCheckbox-colorSecondary.Mui-checked svg {
    fill: #000;
} */

.buildingDtlsInSticky {
  position: sticky;
  top: 0;
  background: #fff;
  z-index: 1;
}
.villageHeadingSticky {
  position: sticky;
  top: 0;
  background: #fff;
  padding: 25px 0;
}
.image-gallery-fullscreen-button {
  display: none;
}
.image-gallery-fullscreen-button .image-gallery-svg,
.image-gallery-play-button .image-gallery-svg {
  display: none;
}

.enqBrchure button {
  box-shadow: 0 2px 4px 0 rgb(0 0 0 / 50%);
  background: #095967;
  border-radius: 100%;
  height: 70px;
  width: 70px;
  padding: 0;
  margin: 0;
  font-size: 10px;
  line-height: 15px;
  color: #fff;
  text-transform: capitalize;
  font-family: 'Conv_AtlasGrotesk-Medium-App';
}
.enqBrchure button:hover {
  background: #095967;
}
.enqBrchure {
  position: absolute;
  bottom: 15px;
  right: 15px;
  z-index: 9;
}
.stationMap {
  margin-top: 20px;
}
.stationMap h3 {
  margin-top: 15px;
  font-family: 'Conv_AtlasGrotesk-Regular-App';
}
.stationLocation {
  margin-top: 20px;
}
.gm-style-mtc {
  display: none;
}
.gm-svpc {
  display: none;
}

.BuildingMap {
  height: 280px;
}
.BuildingMap > div {
  height: 100% !important;
}
.stationMap > div {
  height: 315px !important;
}

/* .gmnoprint {
  left: 0px;
  top: 0;
  bottom: auto;
} */
