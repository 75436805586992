.grossAdded{
    color: #cb0000;
}
.exportdcList button {
    padding: 0;
    margin: 0;
    width: 52px;
    height: 52px;
    border-radius: 100%;
    min-width: 100%;
}
.financeLogo {
    width: 50px;
    height: 50px;
    margin: 0 auto 13px;
    text-align: center;
    border-radius: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
}
.financeIn ul li:hover h5{
    color: #095967;
    -webkit-transition: all ease-in-out 0.3s;
    -o-transition: all ease-in-out 0.3s;
    transition: all ease-in-out 0.3s;
}
.financeIn ul li:hover h6{
    color: #095967;-webkit-transition: all ease-in-out 0.3s;
    -o-transition: all ease-in-out 0.3s;
    transition: all ease-in-out 0.3s;
}
.financeIn ul li .financeLogo img:last-child{
    display: none;
}

.financeIn ul li:hover .financeLogo img:last-child{
    display: block;
}
.financeIn ul li:hover .financeLogo img:first-child{
    display: none;
}


.pageContainer.tableContainer.tenantUserListTable.arrerstatementTable {
    min-height: calc(100vh - 250px);
    padding-bottom: 0;
}
.pageContainer.tableContainer.tenantUserListTable.arrerstatementTableBtm {
    min-height: 100%;
}



.contactsIn.financeIn ul li:hover {
    transition: all ease-in-out 0.3s;
    box-shadow: none;
}
.financeLogo img {
    width: 45px;
}



.accessDialogOpend .MuiPaper-root.MuiDialog-paper {
    display: block;
    width: 100%;
    padding: 40px 0 0;
    -webkit-box-sizing: border-box;
            box-sizing: border-box;
    max-width: 450px !important;
    height: 210px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
}
.accessDialogOpend .accessDialogBtn button {
    margin: 0;
    text-align: center;
    color: #fff;
    height: 47px;
    font-size: 13px;
    background: #000;
    font-family: Conv_AtlasGrotesk-Medium-App;
    line-height: 47px;
    border-radius: 0;
    text-transform: inherit;
    margin: 0 auto;
}
.accessDialogOpend .accessDialogBtn {
    margin: 0;
    padding: 40px 30px;
}

.expandedTableRow > td, 
.expandedTableRow > td button {
    color: #095967 !important;
}

.TotalTableContainer, .TotalTableContainer tr {
    box-shadow: none !important;
    border-radius: 0 !important;
}
.TotalTableContainer tr th {
    box-shadow: none !important;
    border-bottom: none !important;
    color: #000 !important;
    background: transparent !important;
    border-radius: 0;
    padding: 8px 16px 8px 16px !important;
}
.TotalTableContainer {
    padding-right: 15px !important;
    border-bottom: none;
}
.pageContainer.tableContainer.tenantUserListTable.arrerstatementTable > div.arrerstatementTableIn {
    overflow-Y: scroll;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.09);
    box-shadow: none;
}

.twoRowEllips {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
}

tr.expandableTableRow > td:first-child {
    padding-left: 0 !important;
}

.contactsIn.documentsIn {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    width: 100%;
}