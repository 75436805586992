.thumbnail-slider-wrap {
  margin-top: 15px;
  height: 90px;
  overflow: hidden;
}
/* .thumbnail-slider-wrap .slick-track .slick-slide {
  text-align: center;
}
.thumbnail-slider-wrap .slick-track .slick-slide img {
  width: 100%;
} */
/* 
.slick-slide img {
  display: block;
  width: 100% !important;
  height: 100% !important;
} */
video.slick-slide-image {
  width: 100%;
  /* object-fit: fill; */
  height: 430px;
}
/* img.slick-slide-image {
  width: 100%;
} */

/* .slickMainSlider .slick-track > div.slick-slide {
  max-height: 430px;
  overflow: hidden;
} */
.thumbnail-slider-wrap .slick-track > .slick-slide {
    padding: 0 10px;
    cursor: pointer;
}
.thumbnail-slider-wrap .slick-list {
  margin: 0 -10px;
}


.thumbnail-slider-wrap  .slick-slide {
  text-align: center;
  position: relative;
}
.slick-slide:focus {
  outline: none;
}
.slick-slide-title {
  text-transform: capitalize;
}
/* .thumbnail-slider-wrap .slick-track .slick-slide {
  text-align: center;
  margin: 0 20px;
} */
/* .thumbnail-slider-wrap .slick-track .slick-slide{
  margin: 0 10px;
  cursor: pointer;
}
.thumbnail-slider-wrap .slick-track {
  margin: 0 -10px;
} */
.thumbnail-slider-wrap .slick-track .slick-slide.slickSlideThumb {
  height: 90px;
  position: relative;
}
.slick-slide-label {
  color: #fff;
  padding: 10px;
  position: absolute;
  left: 0px;
  font-size: 1.5em;
  bottom: 0px;
  width: 100%;
}
.slick-prev:before,
.slick-next:before {
  color: #777777;
}
.thumbnail-slider-wrap .slick-slide.slick-active.slick-current .slick-slide.slickSlideThumb:after {
  content: "";
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 2px;
  background: #444444;
}
.thumbnail-slider-wrap .slick-slider {
  height: 100%;
}
.thumbnail-slider-wrap  .slick-prev,
.thumbnail-slider-wrap  .slick-next {
  width: 45px;
  height: 110px;
  top: 0;
  transform: none;
}
.thumbnail-slider-wrap .slick-track .slick-slide img {
  width: 100%;
  height: 92%;
}

.thumbnail-slider-wrap .slick-track {
  margin:  0 0;
}
.slideThumbPlay {
  position: absolute;
  bottom: 7px;
  left: 0;
  width: 45px;
}
.slideThumbPlay img {
  width: auto !important;
  height: auto !important;
}
