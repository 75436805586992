.rainbowBoard {
  border: none;
  margin-top: 15px;
  color: #fff;
  width: 100%;
}
.rainbowBoard td {
  padding: 12px 10px;
  font-size: 13px;
  line-height: 18px;
  font-family: 'Conv_AtlasGrotesk-Regular-App';
}
/* .rainbowBoard tr > td:nth-child(1) {
  width: 50%;
} */
.rainbowBoard tr>td:nth-child(1), .rainbowBoard tr>td:nth-child(2) {
  display: table-cell;
}
.rainbowBoard tr>td {
  display: none;
}
.black {
  color: #000 !important;
}
.partClosure,
.severeDelays {
  background: #faf5e1;
}
.londonoverground {
  background: #e86a10;
}
.london-overground {
  background: #e86a10;
}
.piccadilly {
  background: #0019a8;
  color: white;
}
.bakerloo {
  background: #894e24;
  color: #fff;
}
.central {
  background: #dc241f;
}
.circle {
  background: #ffce00;
  color: #0019a8;
}
.district {
  background: #007229;
}
.hammersmithcity {
  background: #d799af;
}
.hammersmith-city {
  background: #d799af;
}
.jubilee {
  background: #6a7278;
}
.metropolitan {
  background: #751056;
}
.northern {
  background: #000;
  color: #fff;
}
.victoria {
  background: #00a0e2;
}
.waterloo-city {
  background: #76d0bd;
  color: #0019a8;
}
.tfl-rail {
  background: #0019a8;
  color: white;
}
.dlr {
  background: #00afad;
}
.tram {
  background: #6c0;
}
